var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tooltip"
  }, [_vm._v(" " + _vm._s(_vm.text !== undefined && _vm.text.length > _vm.size ? _vm.text.substr(0, _vm.size) + '..' : _vm.text) + " "), _c('span', {
    staticClass: "tooltiptext"
  }, [_vm._v(_vm._s(_vm.tooltipText))])]);
}
var staticRenderFns = []

export { render, staticRenderFns }